<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <b-row>
            <b-col>
              <h4>Add Product</h4>
            </b-col>
          </b-row>

          <b-row class="h-100" v-if="state === 'loading'" align-h="center">
            <b-spinner label="Loading..."></b-spinner>
          </b-row>
          
          <b-form v-if="state === 'show'" >
            <b-row>
              <b-col cols="4">
                <label>Product Reference</label>
                <b-form-input v-model="productValues.productRef"></b-form-input>
              </b-col>
              <b-col cols="4">
                <label>Cost Before VAT <span class="text-red">*</span></label>
                <b-form-input v-model="$v.productValues.priceExVat.$model" 
                              @blur="$v.productValues.$touch()"></b-form-input>
                <div v-if="$v.productValues.priceExVat.$error" class="text-red font-weight-400 text-left">
                  <p v-if="!$v.productValues.priceExVat.decimal" >Please enter a valid number</p>
                  <p v-if="!$v.productValues.priceExVat.required" >This is a required field</p>
                </div>
              </b-col>
              <b-col cols="4">
                <label>Manufacturer</label>
                <b-form-input v-model="productValues.manufacturer"></b-form-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="4">
                <label>Short Description</label>
                <b-form-input v-model="productValues.description"></b-form-input>
              </b-col>
              <b-col cols="4">
                <label>Item Code</label>
                <b-form-input v-model="productValues.itemCode"></b-form-input>
              </b-col>
            </b-row>
            <hr class="mx-3">
            <b-row>
              <b-col>
                <div class="d-flex justify-content-end">
                  <div>
                    <b-button variant="red" squared @click="goBackToSearch" class="ml-2">Cancel</b-button>
                  </div>
                  <div>
                    <b-button variant="primary" squared @click="addNewProduct" class="ml-2" :disabled="$v.productValues.$invalid">Save</b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapActions, mapMutations} from "vuex";
import {decimal, required} from "vuelidate/lib/validators";

export default {
  name: "createProduct",
  data: () => ({
    state: 'show',
    productValues: {
      productRef: null,
      priceExVat: null,
      description: null,
      itemCode: null,
      includeVat: true,
      manufacturer: null
    }
  }),
  created() {
    this.setBreadcrumb([
      {
        text: 'Product'
      },
      {
        text: 'Add New Product'
      },
    ])
  },
  methods: {
    ...mapActions(["createProduct"]),
    ...mapMutations('breadcrumbService', ['setBreadcrumb']),
    
    addNewProduct() {
      this.$store.commit('setProductCreateRequest', this.productValues)
      this.state = 'loading'
      this.createProduct()
        .then(() => {
          this.goBackToSearch()
        }).catch(() => {
          this.state = "show"
      })
    },
    
    goBackToSearch(){
      this.$router.back()
    },
  },
  computed:{},
  validations: {
    productValues: {
      priceExVat: {decimal, required},
    }
  },
}
</script>

<style scoped>

</style>